<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">9721见好就收才是赢</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'nine' }}">收费车道设备系列</el-breadcrumb-item>
                <el-breadcrumb-item>隧道内可变信息情报板</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/kbxx8.webp" alt="" style="height: 2.3438rem;">

            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">雾灯</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">雾灯主要是安装在收费站入口,道路分叉口,道路分叉口和危险路段处,为司机提供路况警示作用,提高车辆行驶安全,避免发生交通意外事故。
                    雾灯箱体采用镀锌钢板/不锈钢，具有良好的密封性和防水防尘防锈蚀功能；其亮度可根据需求进行8档调节，确保显示清晰度。
                </P>
                <div style="position: absolute;bottom: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数</div>
        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>型号</em></div>
                    <div class="msg">IF-W230</div>
                </li>
                <li>
                    <div class="txt"><em>单体显示组合</em></div>
                    <div class="msg">黄色灯盘闪烁</div>
                </li>
                <li>
                    <div class="txt"><em>机箱</em></div>
                    <div class="msg">不锈钢机箱，防水、防尘、防锈蚀、密封性IP65</div>
                </li>
                <li>
                    <div class="txt"><em>规格尺寸</em></div>
                    <div class="msg">Φ200mm</div>
                </li>
                <li>
                    <div class="txt"><em>重量</em></div>
                    <div class="msg">8kg</div>
                </li>
                <li>
                    <div class="txt"><em>工作环境</em></div>
                    <div class="msg">温度：-40℃~+65℃；湿度：10%~95%RH</div>
                </li>
                <li>
                    <div class="txt"><em>输入电压</em></div>
                    <div class="msg">AC220V±10%，50Hz±3Hz</div>
                </li>
                <li>
                    <div class="txt"><em>功率消耗</em></div>
                    <div class="msg">9W</div>
                </li>
                <li>
                    <div class="txt"><em>控制方式</em></div>
                    <div class="msg">现场开关控制或远程PLC开关控制</div>
                </li>
                <li>
                    <div class="txt"><em>亮度调节</em></div>
                    <div class="msg">8档自动或设定</div>
                </li>
                <li>
                    <div class="txt"><em>可视距离</em></div>
                    <div class="msg">≥200m</div>
                </li>
                <li>
                    <div class="txt"><em>可视角度</em></div>
                    <div class="msg">＞30°</div>
                </li>
                <li>
                    <div class="txt"><em>显示材料</em></div>
                    <div class="msg">黄色LED管</div>
                </li>
            </ul>
        </div>




        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
                history.back();
            }
            ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>