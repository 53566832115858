<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">9721见好就收才是赢</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'seven' }}">智能收费亭</el-breadcrumb-item>
                <el-breadcrumb-item>智能单向收费亭</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/zhjt1.webp" alt="" style="height:2.3438rem">
            </div>

            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">智能单向收费亭</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">智能收费亭集结构、监控、管理一体化多功能模块，集成了中央总控系统、智能收费系统、自动门窗、门禁管理系统、雾化玻璃控制系统、空调系统、新风系统、智能环境控制及监测系统，以及腿部加热、低功耗可调亮度照明、语音服务、背景音乐、多媒体LED显示屏等功能，为高速公路用户收缴费提供方便智能的工作界面和人性化收费环境。
                    智慧收费亭具有智能、舒适、便捷、节能、环保、隔热、保温等特点，还可根据用户的不同需求定制产品外观和MTC一体化收费系统相关硬件设备，实现人工收费与自动收费切换。
                </P>
                <div style="position: absolute;bottom: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数</div>

        <!--  style="width: 6.5104rem;margin: 0 auto;"-->
        <div class="msgList"  style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>亭体
                    </em></div>
                    <div class="msg">2.7米(L)×1.5(W)米×2.8米(H)，含墙壁插座、隔热防
                        火材料，防静电活动地板和集成吊顶，外壳采用镀锌
                        钢板喷塑，内饰木封板
                        </div>
                </li>
                <li>
                    <div class="txt"><em>门窗
                    </em></div>
                    <div class="msg">门：向外开门，含“机械+电磁锁”与IC卡/密码门禁，
                        白透双层中空钢化玻璃。
                        窗：左侧为平移推拉窗，开关方式为手自一体，带防
                        夹功能。右侧及前窗是固定玻璃。窗户均为双层中空
                        钢化玻璃
                        </div>
                </li>
                <li>
                    <div class="txt"><em>收费椅

                    </em></div>
                    <div class="msg">可转向活动座椅


                    </div>
                </li>


                <li>
                    <div class="txt"><em>集顶中央空调


                    </em></div>
                    <div class="msg">1.5P，冷暖型，定制


                    </div>
                </li>

                <li>
                    <div class="txt"><em>LED照明

                    </em></div>
                    <div class="msg">中央28W LED照明灯，φ300mm，两侧2×15W LED灯带照明，可任意切换开关


                    </div>
                </li>

                <li>
                    <div class="txt"><em>腿部加热模块 

                    </em></div>
                    <div class="msg">超薄纳米材质低功耗腿暖模块，220VAC，温度范围39-46℃，支持自动控制/手动开关模式


                    </div>
                </li>

                <li>
                    <div class="txt"><em>亭内配电箱

                    </em></div>
                    <div class="msg">嵌入式，含市电/UPS供电模组、配线、线端子等，供电回路大于12路


                    </div>
                </li>


                <li>
                    <div class="txt"><em>车道技术柜

                    </em></div>
                    <div class="msg">嵌入式，仅含通风、温控、配线、线槽等，不含收费设备



                    </div>
                </li>

                <li>
                    <div class="txt"><em>智能亭控制系统

                    </em></div>
                    <div class="msg">工业总控制模块，配10寸液晶触摸屏，2个RS232/RS485口，2USB接口，以及控制软件


                    </div>
                </li>


                <li>
                    <div class="txt"><em>新风系统

                    </em></div>
                    <div class="msg">嵌入式安装，220VAC，20W，新风量118-150m3/h，换热效率:60%(高速、低速)，PM2.5过滤效率:99.99%，
                        噪音＜50dB(高速、低速）
                        
                        

                    </div>
                </li>

                <li>
                    <div class="txt"><em>收费窗口风幕

                    </em></div>
                    <div class="msg">嵌入式安装，24VDC，15W，风速两段可调，最大风速11m/s，风量350m3/h，噪音＜48db



                    </div>
                </li>

                <li>
                    <div class="txt"><em>卷帘窗帘

                    </em></div>
                    <div class="msg">前窗和右侧窗户配手动窗帘


                    </div>
                </li>

                
                <li>
                    <div class="txt"><em>环境监控系统


                    </em></div>
                    <div class="msg">包括亭内温湿度、噪音、空气质量（PM2.5/CO/CO2/SO2）等模块



                    </div>
                </li>

                <li>
                    <div class="txt"><em>智能收费亭其它附件



                    </em></div>
                    <div class="msg">包括密封、固定、线缆、接地等




                    </div>
                </li>






            </ul>
        </div>


        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'seven' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
            ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #e7c3c3 solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>



