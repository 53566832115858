<template>
    <div >
      <div style="width: 6.5104rem;margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">9721见好就收才是赢</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'first' }}">交通雷达</el-breadcrumb-item>
          <el-breadcrumb-item>毫米波雷达</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
     
      <div style="display: flex;justify-content: center;margin-top: .4167rem;">
        <div style="display: inline-block;transform: scale(1.05) translateX(-0.6771rem) translateY(.1563rem) ;">
          <img src="../../assets/001.webp" alt="">
        </div>
  
        <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">

          <p class="name">毫米波雷达</p>

          <P class="content" style="text-indent: .1198rem;font-size: .0833rem">
            丰海科技lF-mmWRadar毫米波雷达系列产品，由丰海科技-西电广州研究院智慧交通创新研究中心联合推出。该产品采用国内最先进的雷达数字信号处理算法，支持丰富的事件检测和道路异常检测功能，能对路面交通状态实时监测，还可针对特殊交通应用场景进行定制化开发，适用于智慧高速、港口、隧道、机场、桥梁等领域。
            该产品具有高精度、高稳定性、高可靠性、安装灵活、全天候工作、不受天气干扰等诸多优点。其检测距离可达350m，可覆盖8车道，同时检测和跟踪256个目标，软硬件可扩展性强，虚警率低。
            <!-- <ul>
              <li>采用80-81GHz毫米波频段雷达技术，分辨率高，距离远，穿透能力强。</li>
              <li>探测距离可达350m，支持双向8车道多目标跟踪(最多256个)。</li>
              <li>支持各种交通参数检测，适用各种交通应用场景，准确率高。</li>
              <li>支持丰富的事件检测和道路异常检测功能，实时对路面交通状态进行监测。</li>
              <li>采用国内最先进的雷达数字信号处理算法，虚警率低。</li>
              <li>对外接口采用工业级航空插头，设备防护等级IP66。</li>
              <li>低功耗，整机功耗不大于10W。</li>
              <li>高可靠性，平均无故障时间MTBF不小于90000h。</li>
     
            </ul> -->

         
          </P>
          
          <div style="margin-top: .2604rem;">
            <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
              <icon class="el-icon-phone-outline"></icon>技术热线
            </div>
            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
  
          </div>
  
  
        </div>
  
  
  
      </div>
  
      <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
        详细参数</div>
  
      <!--  style="width: 6.5104rem;margin: 0 auto;"-->
      <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
        <ul>
          <li>
            <div class="txt"><em>工作频率</em></div>
            <div class="msg">80-81GHz</div>
          </li>
          <li>
            <div class="txt"><em>发射功率</em></div>
            <div class="msg">≤20mW</div>
          </li>
          <li>
            <div class="txt"><em>调制波形</em></div>
            <div class="msg">FMCW</div>
          </li>
          <li>
            <div class="txt"><em>扫描带宽</em></div>
            <div class="msg">500MHz</div>
          </li>
          <li>
            <div class="txt"><em>帧率</em></div>
            <div class="msg">20Hz</div>
          </li>
          <li>
            <div class="txt"><em>探测范围</em></div>
            <div class="msg">22m~350m</div>
          </li>
          <li>
            <div class="txt"><em>距离分辨率</em></div>
            <div class="msg">0.3m</div>
          </li>
          <li>
            <div class="txt"><em>测速范围</em></div>
            <div class="msg">-250km/h~+250km/h</div>
          </li>
          <li>
            <div class="txt"><em>速度分辨率</em></div>
            <div class="msg">0.6km/h</div>
          </li>
          <li>
            <div class="txt"><em>车道断面流量(高速公路)准确率</em></div>
            <div class="msg">96%</div>
          </li>
          <li>
            <div class="txt"><em>车道断面流量(市内通畅)准确率</em></div>
            <div class="msg">95%</div>
          </li>
          <li>
            <div class="txt"><em>车道断面流量(市内拥堵)准确率</em></div>
            <div class="msg">92%</div>
          </li>
          <li>
            <div class="txt"><em>探测角度</em></div>
            <div class="msg">水平70°，垂直14°</div>
          </li>
          <!-- <li>
            <div class="txt"><em>亮度调节</em></div>
            <div class="msg">32级亮度控制，手动/自动/定时，可远程设置</div>
          </li> -->
          <li>
            <div class="txt"><em>抗有源干扰能力</em></div>
            <div class="msg">≥30dB(77~79GHz)</div>
          </li>
          <li>
            <div class="txt"><em>抗地杂波能力</em></div>
            <div class="msg">≥60dB</div>
          </li>
          <li>
            <div class="txt"><em>外形尺寸</em></div>
            <div class="msg">215 x 175 x 81mm</div>
          </li>
          <li>
            <div class="txt"><em>重量</em></div>
            <div class="msg">1.2Kg</div>
          </li>
          <li>
            <div class="txt"><em>工作电流</em></div>
            <div class="msg">≤800mA (12V DC)</div>
          </li>
          <li>
            <div class="txt"><em>工作温度</em></div>
            <div class="msg">-40°C~+65°C</div>
          </li>
          <li>
            <div class="txt"><em>工作湿度</em></div>
            <div class="msg">10%~98%</div>
          </li>
          <li>
            <div class="txt"><em>防护等级</em></div>
            <div class="msg">IP66</div>
          </li>
          <li>
            <div class="txt"><em>MTBF</em></div>
            <div class="msg">90000h</div>
          </li>
          <li>
            <div class="txt"><em>功耗</em></div>
            <div class="msg">≤10W</div>
          </li>
          
        </ul>
      </div>
  
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
  
    </div>
  
  
  
  
  </template>
  <script>
  
  
    export default {
      methods: {
        goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'first' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
        ,
  
        //       goTo(){
        //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
        //     const windowHeight = window.innerHeight; // 
        //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
        //     window.scrollTo(0, scrollTo); //
        //   },
      }
    }
  
  
  
  
  </script>
  
  <style scoped>
    .name {
      padding-bottom: .1042rem;
      border-bottom: #eeeeee solid .0052rem;
      font-size: .1042rem;
      color: #2056c0;
      font-weight: bold;
      position: relative;
    }
  
    .msgList {
      padding-top: .2604rem;
  
      border-bottom: #eee solid .0052rem;
    }
  
    .msgList li {
      border: #c6c6c6 solid .0052rem;
      padding: .0521rem .1042rem .0521rem 1.0938rem;
      position: relative;
      font-size: .0729rem;
      margin-bottom: .0521rem;
      text-align: center;
      line-height: .2604rem;
    }
  
    li {
      list-style-position: outside;
      list-style-type: none;
  
    }
  
    .msgList li .msg {
      line-height: .1667rem;
      color: #999;
    }
  
    .content {
      padding-top: .1563rem;
      font-size: .0729rem;
      line-height: .125rem;
      color: #434343;
  
    }
  
    .tel {
      padding-top: .0521rem;
      font-size: .1875rem;
      color: #bb3944;
      font-family: Impact;
    }
  
    .txt {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: .9896rem;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      background: #2056c0;
      font-size: .0729rem;
      font-family: Arial, "微软雅黑";
  
    }
  
    .tittle {
  
      margin-top: .5208rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      font-size: .0833rem;
      color: #fff;
  
      position: relative;
    }
  </style>