<template>
    <div>
      <div style="width: 6.5104rem;margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">9721见好就收才是赢</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'first' }}">交通雷达</el-breadcrumb-item>
          <el-breadcrumb-item>雷视一体机</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  
      <div style="display: flex;justify-content: center;margin-top: .4167rem;">
        <div style="display: inline-block;transform: scale(1.05) translateX(-0.6771rem);">
          <img src="../../assets/003.webp" alt="" >
        </div>
  
        <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
          <p class="name">雷视一体机</p>
          <P class="content" style="text-indent: .1198rem;font-size: .0833rem">
            该产品集道路监控、流量采集和事件检测功能于一体，支持按车道和时段进行车流量、平均速度车道占有率、平均车头时距、平均排队长度和道路交通状态等指标的统计。同时支持车牌识别、车型识别且具备导出功能。
            雷视一体机采用80-81GHz毫米波频段雷达技术，探测距离可达350m，支持双向8车道多目标跟踪，分辨率高、距离远、穿透能力强。
            <!-- <ul>
              <li>采用80-81GHz毫米波频段雷达技术，分辨率高，距离远，穿透能力强。</li>
              <li>探测距离可达350m，支持双向8车道多目标跟踪(最多256个)。</li>
              <li>多样性混合场景应用，集道路监控、流量采集和事件检测于一体。</li>
              <li>对外接口采用工业级航空插头，设备防护等级IP65。</li>
              <li>支持3D降噪、强光抑制、宽动态，并具有多种白平衡模式和ICR切换功能，</li>
              <li>适合各种交通复杂场景需求。</li>
              <li>低功耗，整机功耗不大于35W。</li>
              <li>高可靠性，平均无故障时间MTBF不小于90000h。</li>
            </ul> -->
          </P>
          <div style="margin-top: .2604rem;">
            <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
              <icon class="el-icon-phone-outline"></icon>技术热线
            </div>
            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
  
          </div>
  
  
        </div>
  
  
  
      </div>
  
      <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
        详细参数</div>
  
      <!--  style="width: 6.5104rem;margin: 0 auto;"-->
      <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
        <ul>
          <li>
            <div class="txt"><em>雷达工作频率</em></div>
            <div class="msg">80-81GHz</div>
          </li>
          <!-- ≥   ≤ -->
          <li>
            <div class="txt"><em>发射功率</em></div>
            <div class="msg">≤20mW</div>
          </li>
          <li>
            <div class="txt"><em>调制波形</em></div>
            <div class="msg">FMCW</div>
          </li>
          <li>
            <div class="txt"><em>扫描带宽</em></div>
            <div class="msg">500MHz</div>
          </li>
          <li>
            <div class="txt"><em>帧率</em></div>
            <div class="msg">20Hz</div>
          </li>
          <li>
            <div class="txt"><em>探测范围</em></div>
            <div class="msg">22m~350m</div>
          </li>
          <li>
            <div class="txt"><em>距离分辨率</em></div>
            <div class="msg">0.3m</div>
          </li>
          <li>
            <div class="txt"><em>车道断面流量(高速公路)准确率</em></div>
            <div class="msg">98%</div>
          </li>
          <li>
            <div class="txt"><em>车道断面流量(市内通畅)准确率</em></div>
            <div class="msg">97%</div>
          </li>
          <li>
            <div class="txt"><em>车道断面流量(市内拥堵)准确率</em></div>
            <div class="msg">94%</div>
          </li>
          <!-- <li>
            <div class="txt"><em>速度分辨率</em></div>
            <div class="msg">0.65公里/小时</div>
          </li> -->
          <li>
            <div class="txt"><em>视频分辨率</em></div>
            <div class="msg">1080p(1920x1080)/UXGA(1600x1200)/720p(1280x720)/D1(704x576)/CIF(352x288)</div>
          </li>
          <li>
            <div class="txt"><em>外形尺寸</em></div>
            <div class="msg">168 x 156 x 350mm</div>
          </li>
          <li>
            <div class="txt"><em>重量</em></div>
            <div class="msg">8.6Kg</div>
          </li>
          <li>
            <div class="txt"><em>工作电流</em></div>
            <div class="msg">≤3A (12V DC)</div>
          </li>
          <li>
            <div class="txt"><em>功耗</em></div>
            <div class="msg">≤35W</div>
          </li>
       
        </ul>
      </div>
  
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
  
    </div>
  
  
  
  
  </template>
  <script>
  
  
    export default {
      methods: {
        goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'first' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
        ,
  
        //       goTo(){
        //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
        //     const windowHeight = window.innerHeight; // 
        //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
        //     window.scrollTo(0, scrollTo); //
        //   },
      }
    }
  
  
  
  
  </script>
  
  <style scoped>
    .name {
      padding-bottom: .1042rem;
      border-bottom: #eeeeee solid .0052rem;
      font-size: .1042rem;
      color: #2056c0;
      font-weight: bold;
      position: relative;
    }
  
    .msgList {
      padding-top: .2604rem;
  
      border-bottom: #eee solid .0052rem;
    }
  
    .msgList li {
      border: #c6c6c6 solid .0052rem;
      padding: .0521rem .1042rem .0521rem 1.0938rem;
      position: relative;
      font-size: .0729rem;
      margin-bottom: .0521rem;
      text-align: center;
      line-height: .2604rem;
    }
  
    li {
      list-style-position: outside;
      list-style-type: none;
  
    }
  
    .msgList li .msg {
      line-height: .1667rem;
      color: #999;
    }
  
    .content {
      padding-top: .1563rem;
      font-size: .0729rem;
      line-height: .125rem;
      color: #434343;
  
    }
  
    .tel {
      padding-top: .0521rem;
      font-size: .1875rem;
      color: #bb3944;
      font-family: Impact;
    }
  
    .txt {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: .9896rem;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      background: #2056c0;
      font-size: .0729rem;
      font-family: Arial, "微软雅黑";
  
    }
  
    .tittle {
  
      margin-top: .5208rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      font-size: .0833rem;
      color: #fff;
  
      position: relative;
    }
  </style>